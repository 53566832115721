<template>
  <v-container>
    <h1>{{ currentLang == "swe" ? "FUTF-Inställningar" : "FUTF-Settings" }}</h1>
    <!-- <h2>{{currentLang == "swe" ? "GDPR-grejer (behöver bättre titel:P)": "GDPR-stuff (needs a better title:P)"}} </h2> -->
    <textloader-component textKey="GDPRText"></textloader-component>
    <v-switch
      v-model="consentSwitch"
      :label="currentLang == 'swe' ? 'Consent-knapp' : 'Consent-button'"
      @change="updateConsentSwitch()"
    ></v-switch>
    <div v-if="isApp() && hasConsent">
      <h2>{{ currentLang == "swe" ? "Startsida" : "Home Page" }}</h2>
      <p>
        {{
          currentLang == "swe"
            ? "Din nuvarande startsida är"
            : "Your current home page is"
        }}:
      </p>
      <v-select
        v-model="currentDefault"
        :items="buttons"
        :item-text="(item) => (currentLang == 'swe' ? item.text : item.text_en)"
        return-object
      >
      </v-select>
    </div>
    <v-switch
      v-model="darkSwitch"
      label="Dark Mode"
      @click="changeTheme()"
    ></v-switch>
  </v-container>
</template>

<script>
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  name: 'Settings',
  components: {
    TextloaderComponent
  },
  data: function () {
    return {
      currentDefault: undefined,
      consentSwitch: undefined,
      darkSwitch: this.$vuetify.theme.dark,
      buttons: [
        // text is what's written on the button
        // route is the route that should be set as the default if button is pressed
        // name is the name that will be saved in store.js
        {
          text: 'Hem',
          text_en: 'Home',
          route: '/',
          name: 'home'
        },
        {
          text: 'Schema',
          text_en: 'Schedule',
          route: '/schema',
          name: 'schema'
        }
      ]
    }
  },
  computed: {
    currentLang: function () {
      return this.$store.getters.lang
    },
    hasConsent: function () {
      return this.$store.getters.consent
    }
  },
  methods: {
    isApp: function () {
      if (navigator.standalone || matchMedia('(display-mode: standalone)').matches) {
        return true
      } else {
        return false
      }
    },
    updateConsentSwitch: function () {
      this.$store.commit('changeConsent', this.consentSwitch)
      this.$store.commit('changeDark', this.darkSwitch)
      this.$store.commit('changeLang', this.currentLang)
    },
    defaultButtonObj: function () {
      const landingPage = this.$store.getters.landingPage
      if (landingPage !== undefined) {
        for (let i = 0; i < this.buttons.length; i++) {
          const button = this.buttons[i]
          if (button.route === landingPage) {
            return button
          }
        }
      } else {
        return this.buttons[0] // should be Home
      }
    },
    changeTheme: function () {
      this.$store.commit('changeDark', this.darkSwitch)
    }
  },
  watch: {
    currentDefault: function (newDefault) {
      this.$store.commit('changeLandingPage', newDefault.route)
    }
  },
  mounted: function () {
    this.consentSwitch = this.$store.getters.consent
    this.currentDefault = this.defaultButtonObj()
  }
}
</script>
